import React from 'react';
import './App.css';

function App() {
  return (
    <div className="App">
      <div className="content">
        <img className="logo" src="logo.png" alt="logo" />
        <div className="intro">
          thanks you for visiting<br />
          <br />
        </div>
        <div className="catalogue next">
          <div className="title shut-up">
            2024
          </div>
          <div className="body">
            <span aria-label="hearty" role="img">❤️❤️ </span>new music coming in 2024 <span aria-label="hearty" role="img">❤️❤️</span>
          </div>
        </div>
        <div className="catalogue next music-video">
          <div className="title ">
            2024 - no more chances video
          </div>
          <iframe height="500px" className="youtube-video" src="https://www.youtube.com/embed/ZEKutPbfzwo?si=CShfp2gIo_yQ5iyF" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
          <div className="body-bottom">
            directed by Ed Diven<br/>
            website: <a href="https://edlanediven.com">edlanediven.com</a><br/>
            instagram: <a href="https://instagram.com/edlanediven">@edlanediven</a>
          </div>
        </div>
        <div className="older">PAST RELEASES</div>
        <div className="catalogue twenty-twenty-three-songs">
          <div className="title ">
            2023 songs
          </div>
          <div className="body">
            recorded in 2023 in nyc<br />
            off-white glove co-written with Alex
          </div>
          <iframe title="song" width="100%" height="100" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1496504671&color=%23ff5500&inverse=true&auto_play=false&show_user=true"></iframe>
          <iframe title="song" width="100%" height="100" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1466121403&color=%23ff5500&inverse=true&auto_play=false&show_user=true"></iframe>
        </div>
        <div className="catalogue shut-up">
          <div className="title shut-up">
            2022 - shut up
          </div>
          <div className="body">
            recorded from 2019 to 2022<br />
            In San Francisco<br />
          </div>
          <iframe title="song" width="100%" height="450" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/1488919138&color=%23ff5500&auto_play=false&hide_related=false&show_comments=false&show_user=false&show_reposts=false&show_teaser=false"></iframe>
        </div>
        <div className="catalogue twenty-twenty-songs">
          <div className="title ">
            2020 songs
          </div>
          <div className="body">
            recorded in 2020 in San Francisco
          </div>
          <iframe title="song" width="100%" height="100" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/756418093&color=%23ff5500&inverse=true&auto_play=false&show_user=true"></iframe>
          <iframe title="song" width="100%" height="100" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/772387963&color=%23ff5500&inverse=true&auto_play=false&show_user=true"></iframe>
        </div>
        <div className="catalogue phase">
          <div className="title">
            2017 - Phase
          </div>
          <div className="body">
            recorded Spring in 2015 to Winter in 2016<br />
            In San Francisco & Indianapolis<br />
            Drums on The Same Way by Sean<br />
            Thanks to Gary, Newman, Alex, too.<br />
            Mastering by Dave and Cover by Kevin
          </div>
          <iframe title="song" width="100%" height="450" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/295859980&color=%23ff5500&auto_play=false&hide_related=false&show_comments=false&show_user=false&show_reposts=false&show_teaser=false"></iframe>
        </div>
        <div className="catalogue fight-off">
          <div className="title">
            2015 - Fight OFF
          </div>
          <div className="body">
            recorded in Chicago and San Francisco from 2012-2015<br />
            thanks to Gary, and Sean for helping with spy
          </div>
          <iframe title="song" width="100%" height="450" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/133777910&color=%23ff5500&auto_play=false&hide_related=false&show_comments=false&show_user=false&show_reposts=false&show_teaser=false"></iframe>
        </div>
        <div className="catalogue demos">
          <div className="title">
            old stuff
          </div>
          <div className="body">
            recorded in Chicago and Indianapolis from 2009-2011<br />
          </div>
          <iframe title="song" width="100%" height="450" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/1803422&color=%23ff5500&auto_play=false&hide_related=false&show_comments=false&show_user=false&show_reposts=false&show_teaser=false"></iframe>
        </div>
      </div>
      <div className="footer">
        contact: <a href="mailto:cmndrcmdnr@gmail.com">cmndrcmdnr@gmail.com</a><br />
      </div>
    </div>
  );
}

export default App;
